















import { Vue, Component, Prop } from 'vue-property-decorator';
import { DialogComponent } from 'vue-modal-dialogs';

@Component
export default class ConfirmationDialog extends DialogComponent<boolean> {
    
    @Prop()
    public title: string;

    @Prop()
    public content: string;

    @Prop()
    public confirmationText: string;

    @Prop()
    public isDangerousAction: boolean;

    private confirm() {
        this.$close(true);
    }

    private cancel() {
        this.$close(false);
    }

}

