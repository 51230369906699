









import { Vue, Component, Prop } from 'vue-property-decorator';
import { PostHeader, PostBody, PostFooter } from '@components/Posts';

@Component({
    components: { PostHeader, PostBody, PostFooter }
})
export default class Post extends Vue {
    
    @Prop({ required: true })
    public post: any;

}

