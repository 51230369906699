








import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { Icon } from '@components/UI';
import { IPost, IRatings, VoteType } from '@shared/types';

@Component({
    components: { Icon }
})
export default class Vote extends Vue {
    
    @Prop({ default: 'up' })
    public type: string;

    @Prop({ required: true })
    public post: IPost;

    @Getter
    public rating: (id: number) => IRatings;

    @Getter
    public hasUpvoted: (id: number) => boolean;

    @Getter
    public hasDownvoted: (id: number) => boolean;

    @Action
    public ratePost: ({ postId, rating }: { postId: number, rating: VoteType }) => Promise<any>;

    public get upvotes() {
        return this.rating(this.post.id).upvotes;
    }

    public get downvotes() {
        return this.rating(this.post.id).downvotes;
    }

    public get hasUserUpvoted() {
        return this.hasUpvoted(this.post.id);
    }

    public get hasUserDownvoted() {
        return this.hasDownvoted(this.post.id);
    }

    public get voteType(): VoteType {
        if (this.type === 'up') {
            if (this.hasUserUpvoted)
                return VoteType.REMOVE;
            
            return VoteType.UPVOTE;
        }

        if (this.type === 'down') {
            if (this.hasUserDownvoted)
                return VoteType.REMOVE;

            return VoteType.DOWNVOTE;
        }

        return VoteType.REMOVE;
    }

    public async vote() {
        const response = await this.ratePost({ postId: this.post.id, rating: this.voteType });
    }

}

