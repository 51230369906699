





























import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { Item, Avatar, DropdownMenu, IconButton, Icon } from '@components/UI';

@Component({
    components: { Item, Avatar, DropdownMenu, IconButton, Icon }
})
export default class ClipPost extends Vue {
    @Prop()
    private clip: any;

    getUrl() {
        return `${this.clip.embed_url}&autoplay=false`;
    }

}

