





































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { Item, Avatar, DropdownMenu, IconButton, Icon, Checkbox } from '@components/UI';
import { IPoll, IPollOption, IPollVote } from '@shared/types';

@Component({
    components: { Item, Avatar, DropdownMenu, IconButton, Icon, Checkbox }
})
export default class PollPost extends Vue {
    @Prop({required: true})
    private poll: IPoll;


    getTimeLeft() {
        //var diff = Math.abs(Date.now() - this.poll.pollEnd.getTime());
        var cdate = new Date(this.poll.pollEnd);
        var diff = Math.abs(Date.now() - cdate.getTime())/1000

        var days = Math.round(diff / 86400);
        var hours   = Math.floor(diff / 3600);
        var minutes = Math.floor((diff - (hours * 3600)) / 60);
        var seconds = diff - (hours * 3600) - (minutes * 60);

        var timeLeft = "";
        if(days > 1)
            timeLeft = `<strong>${days}</strong> day(s)`;
        else if(hours > 0)
            timeLeft = `<strong>${hours}</strong> hour(s)`;
        else if(minutes > 0){
            if(seconds > 0)
                timeLeft = `<strong>${minutes}</strong> minute(s) and <strong>${seconds}</strong> second(s)`;
            else
                timeLeft = `<strong>${minutes}</strong> minute(s)`;
        }
        else
            timeLeft = `<strong>${seconds}</strong> seconds`;

        return timeLeft;
        
    }

    getAuthor() {
        if(this.poll != null && this.poll.author != null && this.poll.author != null)
            return this.poll.author;
        else
            return null;
    }
    getProfile() {
        var usr = this.getAuthor();
        if(usr != null && usr.profile != null)
            return this.poll.author.profile;
        else
            return null;
    }
    getAvatar() {
        var prof = this.getProfile();
        if(prof == null) return "";

        console.log(prof.avatar);
        return prof.avatar;
    }
    getUsername() {
        var prof = this.getAuthor();
        if(prof == null) return "";

        return prof.username;
    }
}

