












import { Vue, Component, Prop } from 'vue-property-decorator';
import { LazyImageLoader } from '@components/Util';

@Component({
    components: { LazyImageLoader }
})
export default class LazyImage extends Vue {
    
    @Prop()
    public src: string;

    private onClick(e): void {
        this.$emit('click', e);
    }

}

