














import { Vue, Component, Prop } from 'vue-property-decorator';
import { IPost } from '@shared/types';
import  LazyImage from '@components/UI/LazyImageScaleable.vue';

@Component({
    components: { LazyImage }
})
export default class PostBody extends Vue {
    
    @Prop()
    public post: IPost;

    getStyle() {
        return (this.post.isVideo) ? null : "500px";
    }
}

