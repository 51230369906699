












import { Vue, Component, Prop } from 'vue-property-decorator';
import { IPost } from '@shared/types';
import { Vote } from '@components/Posts';

@Component({
    components: { Vote }
})
export default class PostFooter extends Vue {
    
    @Prop({ required: true })
    public post: IPost;

}

