














import { Vue, Component, Prop } from 'vue-property-decorator';
import { DialogComponent } from 'vue-modal-dialogs';

@Component
export default class AlertDialog extends DialogComponent<boolean> {
    
    @Prop()
    public title: string;

    @Prop()
    public content: string;

    private close() {
        this.$close(true);
    }

}

