






































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Item, Avatar, DropdownMenu, IconButton, Icon } from '@components/UI';
import { IPost } from '@shared/types';
import { ConfirmationDialog, AlertDialog } from '@components/Dialogs';
import { create } from 'vue-modal-dialogs';
import Moment from 'moment';

interface ConfirmData {
    title?: string;
    content: string;
    confirmationText: string;
    isDangerousAction: boolean;
}

interface AlertData {
    title?: string;
    content: string;
}

@Component({
    components: { Item, Avatar, DropdownMenu, IconButton, Icon }
})
export default class PostHeader extends Vue {
    
    @Prop({ required: true })
    public post: IPost;

    @Getter
    public isAdministrator: boolean;

    @Getter
    public isVip: boolean;

    @Action
    public adminDeleteImage: (any) => Promise<any>;

    @Action
    public adminFeatureImage: (any) => Promise<any>;

    @Action
    public sharePost: (any) => Promise<any>;

    private confirmationDialog = create<ConfirmData>(ConfirmationDialog);
    private alertDialog = create<ConfirmData>(AlertDialog);

    private get date(): string {
        return Moment(this.post.date * 1000).fromNow();
    }

    private async sharePostToDiscord() {
        try {
            const response = await this.sharePost({ id: this.post.fileName });

            if (response.success) {
                this.alertDialog({ content: 'Congrats, everyone hates you... I mean you.. just shared the image to discord or something!' });
            } else {
                this.alertDialog({ content: 'OOPSIE WOOPSIE!! Uwu We made a fucky wucky!! A wittle fucko boingo! The code monkeys at our headquarters are working VEWY HAWD to fix this!' })
            }
        } catch (error) {
            
        }
    }

    private async featurePost() {
        if (await this.confirmationDialog({ 
            content: 'You are about to feature this post? Are you sure you really want to feature it? I mean come on, it really can\'t be that great, right?', 
            confirmationText: 'Feature Post', 
            isDangerousAction: false
        })) {
            const response = await this.adminFeatureImage({ id: this.post.id });

            if (response.data.success) {
                this.alertDialog({ content: 'Wow, you actually featured it... I\'m dissapointed in you.' });
            } else {
                this.alertDialog({ content: 'OOPSIE WOOPSIE!! Uwu We made a fucky wucky!! A wittle fucko boingo! The code monkeys at our headquarters are working VEWY HAWD to fix this!' })
            }
        }
    }

    private async deletePost() {
        if (await this.confirmationDialog({ 
            content: 'You are about to delete this post. This action is cannot be undone. Are you sure you want to delete this post?', 
            confirmationText: 'Delete Post', 
            isDangerousAction: true 
        })) {
            const response = await this.adminDeleteImage({ id: this.post.id, pending: false });

            if (response.data.success) {
                this.alertDialog({ content: 'Wow, you actually deleted it! Well, it\'s gone now, you can\'t have it back. No, seriously. What do you think we are, Facebook?' });
            } else {
                this.alertDialog({ content: 'OOPSIE WOOPSIE!! Uwu We made a fucky wucky!! A wittle fucko boingo! The code monkeys at our headquarters are working VEWY HAWD to fix this!' })
            }

        }
    }

}

